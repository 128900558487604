<template>
  <div id="Pessoa_DadosBasicos">
    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title :style="{ 'color':COR_SUBTITULO }" class="d-flex align-center justify-space-between body-1 font-primary ml-2">
      <p class="font-primary font-weight-bold font-weight-medium body-1" :style="{ 'color':COR_SUBTITULO }">
        Dados Básicos 
      </p>
     
      <v-btn 
        v-if="store_Pessoa.acao == 'C'"
        @click="clickBtnEditar()"
        class="btn" elevation="0"
        :disabled="!store_usuario.priv_busca_privilegios_aplicacao_evento('Pessoa.Editar')">
        <v-icon class="btn-icon mr-1" color="orange accent-4">mdi-pencil-outline</v-icon>
        <span class="text-none font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Editar</span>
      </v-btn>
    </v-toolbar-title>

    <!-------- FORM CADASTRO ----------------------------->
    <v-form
      v-model="valid"
      ref="form"
      class="mx-1 mt-3"
      :key="key_cnpj"
      lazy-validation
    >
      <v-row>

      <!-- PRIMEIRA COLUNA / RADIOS ------------------------>
        <v-col 
          :cols="isMobile ? 12 : 4" 
          sm="4" 
          class="mt-n1 py-0 pl-2">
          <v-radio-group 
              mandatory
              row
              class="px-2 small-radio"
              v-model="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica"
              :disabled="store_Pessoa.acao != 'I'">
            <v-radio
              key="1"
              label="Pessoa Física"
              value="Física"
              class="mr-4"
              checked="checked"
            ></v-radio>

            <v-spacer></v-spacer>
            <v-radio
              key="2"
              label="Pessoa Jurídica"
              value="Jurídica"
            ></v-radio>
          </v-radio-group>
        </v-col>
      <!-- FIM PRIMEIRA COLUNA / RADIOS ------------------------>

      <!-- <v-row v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" class="col-terceira-pf"> -->
        <v-container v-if="isMobile">      
          <v-col 
            :cols="isMobile ? 12 : 4"
            sm="4"
            class="pt-0 px-2 justify-center">
            <div class="d-flex justify-center mt-n9 pt-5">
              <v-avatar
                size="100px"
                v-ripple
                v-if="store_Pessoa.acao == 'C'"
                class="grey lighten-2"
              >
                <span
                  v-if="!store_Pessoa.pessoa_edicao.pessoa_foto"
                  color="#274ABB"
                  class="headline"
                  >
                  {{ nomeIniciais(store_Pessoa.pessoa_edicao.pessoa_nome) }}
                </span>
                <img
                  v-if="store_Pessoa.pessoa_edicao.pessoa_foto"
                  :src="store_Pessoa.pessoa_edicao.pessoa_foto"
                  alt="avatar"
                />
              </v-avatar>

              <v-avatar
                size="100px"
                v-ripple
                v-if="store_Pessoa.acao != 'C'"
                class="grey lighten-2"
              >
                <span
                  v-if="!store_Pessoa.pessoa_edicao.pessoa_foto"
                  v-on:click="dialog_crop = true">
                  Clique p/ adicionar avatar
                </span>
                <img
                  v-if="store_Pessoa.pessoa_edicao.pessoa_foto"
                  v-on:click="dialog_crop = true"
                  :src="store_Pessoa.pessoa_edicao.pessoa_foto"
                  alt="avatar"
                />
              </v-avatar>
            </div>
          </v-col>
        </v-container>


      <!-- SEGUNDA COLUNA / PF ------------------------>
        <v-col v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" cols="12" sm="8" class="pt-0 px-2 pb-0">
          <v-text-field
            v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'"
            v-model="store_Pessoa.pessoa_edicao.pessoa_nome"
            :readonly="store_Pessoa.acao == 'C'"
            class="input-nome mb-0 obrigatorio"
            loader-height="1"
            background-color="#FFF"
            label="Nome"
            placeholder="Nome..."
            light
            filled
            autofocus
            outlined
            maxlength="60"
            required
            dense
            :rules="nameRules"
          ></v-text-field>
        </v-col>
      <!-- FIM SEGUNDA COLUNA / PF ------------------------>

      <!-- SEGUNDA COLUNA / PJ ------------------------>
        <v-col cols="12" sm="8" class="pt-2 px-2 pb-0" v-else>
          <v-text-field
          v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Jurídica'"
          v-model="store_Pessoa.pessoa_edicao.pessoa_nome"
          :readonly="store_Pessoa.acao == 'C'"
          class="input-razao mb-0 obrigatorio"
          loader-height="1"
          background-color="#FFF"
          label="Razão Social"
          placeholder="Razão Social"
          light
          filled
          outlined
          required
          dense
          :rules="razaoRules"
           ></v-text-field>
        </v-col>
      <!-- FIM SEGUNDA COLUNA / PJ ------------------------>
      </v-row>

    <!-- TERCEIRA COLUNA / PF ------------------------>
      <v-row v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" class="col-terceira-pf">
        <v-col  
          :cols="isMobile ? 12 : 4"
          sm="4" 
          class="pt-0 px-2 justify-center">
          <div class="d-none d-sm-flex justify-center mt-n11 pt-5">
            <v-avatar
              size="100px"
              v-ripple
              v-if="store_Pessoa.acao == 'C'"
              class="grey lighten-2"
            >
              <span
                v-if="!store_Pessoa.pessoa_edicao.pessoa_foto"
                color="#274ABB"
                class="headline"
                >
                {{ nomeIniciais(store_Pessoa.pessoa_edicao.pessoa_nome) }}
              </span>
              <img
                v-if="store_Pessoa.pessoa_edicao.pessoa_foto"
                :src="store_Pessoa.pessoa_edicao.pessoa_foto"
                alt="avatar"
              />
            </v-avatar>

            <v-avatar
              size="100px"
              v-ripple
              v-if="store_Pessoa.acao != 'C'"
              class="grey lighten-2"
            >
              <span
                v-if="!store_Pessoa.pessoa_edicao.pessoa_foto"
                v-on:click="dialog_crop = true">
                Clique p/ adicionar avatar
              </span>
              <img
                v-if="store_Pessoa.pessoa_edicao.pessoa_foto"
                v-on:click="dialog_crop = true"
                :src="store_Pessoa.pessoa_edicao.pessoa_foto"
                alt="avatar"
              />
            </v-avatar>
          </div>
        </v-col>

        <v-col cols="12" sm="4" class=" pt-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.pessoa_cpf"
            :readonly="store_Pessoa.acao == 'C'"
            v-mask="MASK_CPFCNPJ"
            class="mt-n2 mb-0 obrigatorio"
            loader-height="1"
            background-color="#FFF"
            label="CPF"
            placeholder="CPF"
            light
            filled
            outlined
            required
            dense
            max="14"
            :rules="cpfRules"
          ></v-text-field>
        </v-col>

        <v-col sm="4" class=" pt-0 px-2">

          <DatePicker
            v-model="store_Pessoa.pessoa_edicao.pessoa_nasc_dt"
            label="Data do Nascimento"
            placeholder="dia/mês/ano"
            class="mr-0 mt-n2"
            outlined
            backgroundColor="#FFF"
            tabindex="0"
            dense
            :rules="dataRules"
            format="DD/MM/YYYY"
            :readonly="store_Pessoa.acao == 'C'"
          ></DatePicker>

        </v-col>
      </v-row>
    <!-- FIM TERCEIRA COLUNA / PF ------------------------>  

    <!-- TERCEIRA COLUNA / PJ ------------------------>
      <v-row v-else class="col-terceira-pj">
        <v-col cols="12" sm="4" class="px-2">
          <div class="d-none d-sm-flex justify-center mt-n3 mb-n6 pt-2">
            <v-avatar
              size="100px"
              v-ripple
              v-if="store_Pessoa.acao == 'C'"
              class="grey lighten-2"
            >
              <span
                v-if="!store_Pessoa.pessoa_edicao.pessoa_foto"
                color="#274ABB"
                class="headline"
                >
                {{ nomeIniciais(store_Pessoa.pessoa_edicao.pessoa_nome) }}
              </span>
              <img
                v-if="store_Pessoa.pessoa_edicao.pessoa_foto"
                :src="store_Pessoa.pessoa_edicao.pessoa_foto"
                alt="avatar"
              />
            </v-avatar>

            <v-avatar
              size="100px"
              v-ripple
              v-if="store_Pessoa.acao != 'C'"
              class="grey lighten-2"
            >
              <span
                v-if="!store_Pessoa.pessoa_edicao.pessoa_foto"
                v-on:click="dialog_crop = true">
                Clique p/ adicionar avatar
              </span>
              <img
                v-if="store_Pessoa.pessoa_edicao.pessoa_foto"
                v-on:click="dialog_crop = true"
                :src="store_Pessoa.pessoa_edicao.pessoa_foto"
                alt="avatar"
              />
            </v-avatar>
          </div>
        </v-col>

        <v-col cols="12" sm="4" class=" pt-0 px-2" v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica == 'Jurídica'">
            <v-text-field
            v-model="store_Pessoa.pessoa_edicao.pessoa_cnpj"
            :readonly="store_Pessoa.acao == 'C'"
            v-mask="MASK_CPFCNPJ"
            class="mt-n2 mb-0 obrigatorio"
            light
            loader-height="1"
            background-color="#FFF"
            label="CNPJ"
            placeholder="CNPJ"
            filled
            outlined
            :rules="cnpjRules"
            required
            dense
            max="18"
            >
            <template v-slot:append>
              <v-icon 
                :disabled="store_Pessoa.acao == 'C' || (!!store_Pessoa.pessoa_edicao.pessoa_cnpj && store_Pessoa.pessoa_edicao.pessoa_cnpj.length < 18)"
                class="mr-1 ml-1" 
                color="primary accent-4"
                @click="buscaDadosCnpj(store_Pessoa.pessoa_edicao.pessoa_cnpj)">mdi-magnify                    
              </v-icon>
            </template></v-text-field>
        </v-col>

        <v-col cols="12" sm="4" class=" pt-0 px-2">
          <DatePicker
            v-model="store_Pessoa.pessoa_edicao.pessoa_firma_abertura_dt" 
            label="Data de Abertura"
            placeholder="dia/mês/ano"
            class="mr-0 mt-n2"
            outlined
            backgroundColor="#FFF"
            tabindex="0"
            dense
            :rules="dataRules"
            format="DD/MM/YYYY"
            :readonly="store_Pessoa.acao == 'C'"
          ></DatePicker>

        </v-col>
      </v-row>
    <!-- FIM TERCEIRA COLUNA / PJ ------------------------>

    <!-- QUARTA COLUNA / PF ------------------------>
      <v-row v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" class="col-quarta-pf mt-n6" >

        <v-col cols="4" class="d-none d-sm-flex"></v-col>

        <v-col cols="12" sm="4" class="pt-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.pessoa_rg"
          :readonly="store_Pessoa.acao == 'C'"
          class="input-rg mb-0"
          background-color="#FFF"
          label="RG"
          filled
          outlined
          maxlength="15"
          dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="4" class="pt-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.pessoa_rg_orgao"
            :readonly="store_Pessoa.acao == 'C'"
            class="input-rg mb-0"
            light
            loader-height="1"
            background-color="#FFF"
            label="RG - Orgão Expedidor"
            placeholder="RG - Orgão Expedidor"
            filled
            maxlength="20"
            outlined
            required
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    <!-- FIM QUARTA COLUNA / PF ------------------------>

    <!-- QUARTA COLUNA / PJ ------------------------>
      <v-row v-else class="col-quarta-pj mt-n6 ">

        <v-col cols="4" class="d-none d-sm-flex"></v-col>

        <v-col cols="12" sm="4" class="pt-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.pessoa_insc_estadual"
            :readonly="store_Pessoa.acao == 'C'"
            class="input-inscricao mb-0"
            background-color="#FFF"
            label="Inscrição Estadual"
            placeholder="Inscrição Estadual"
            filled
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="4" class="pt-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.pessoa_insc_municipal"
            :readonly="store_Pessoa.acao == 'C'"
            class="input-inscricao"
            light
            loader-height="1"
            background-color="#FFF"
            label="Inscrição Municipal"
            placeholder="Inscrição Municipal"
            filled
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    <!-- QUARTA COLUNA / PJ ------------------------>

    <!-- QUINTA COLUNA / PF ------------------------>
      <v-row v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" class="mt-n2 mb-1">
        <v-col cols="12" sm="4" md="2" lg="3" class="py-0 px-2">
          <v-select 
          v-model="store_Pessoa.pessoa_edicao.pessoa_sexo"
          :readonly="store_Pessoa.acao == 'C'"
          class="mt-n2 obrigatorio"
          label="Sexo"
          filled
          outlined
          dense
          background-color="map-deep-merge white"
          :items="arraySexo"
          item-text="name"
          :rules="sexoRules"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="4" lg="3" class="py-0 px-2">
          <v-autocomplete
          v-model="store_Pessoa.pessoa_edicao.pessoa_nacionalidade"
          :readonly="store_Pessoa.acao == 'C'"
          label="Nacionalidade"
          class="mt-n2"
          filled
          outlined
          dense
          background-color="#FFF"
          :items="arrayPaises"
          item-text="nome"
          ></v-autocomplete>
        </v-col>

          <v-col v-if="store_Pessoa.acao == 'C'" cols="12" sm="4" class="py-0 px-2">
            <v-text-field 
              v-model="textMunicipio" 
              class="mt-n2" 
              loader-height="1" 
              background-color="#FFF" 
              label="Naturalidade"
              placeholder="Naturalidade" 
              light 
              filled 
              outlined 
              required 
              :readonly="store_Pessoa.acao == 'C'"
              dense></v-text-field>
          </v-col>
        <v-col cols="12" sm="4" class="py-0 px-2" v-if="store_Pessoa.acao == 'E'">
            <v-autocomplete
              v-model="store_Pessoa.pessoa_edicao.cod_municipio"
              :loading="loading"
              :search-input.sync="search"
              :items="arrayMunicipios"
              no-filter
              hide-selected
              clearable
              class="mt-n2"
              label="Naturalidade"
              placeholder="Naturalidade"
              item-text="nome" 
              item-value="codigo"
              auto-select-first  
              filled 
              outlined 
              dense 
              background-color="map-deep-merge white" 
              :readonly="store_Pessoa.acao == 'C'"
            />
          </v-col>

        <v-col cols="12" sm="1" md="2" lg="2" class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.pessoa_creci"
          :readonly="store_Pessoa.acao == 'C'"
          class="mt-n2 mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Creci"
          placeholder="Creci"
          filled
          outlined
          dense
          :rules="[rules.max(15, store_Pessoa.pessoa_edicao.pessoa_creci)]"
          ></v-text-field>
        </v-col>
      </v-row>
    <!-- QUINTA COLUNA / PF ------------------------>

    <!-- QUINTA COLUNA / PJ ------------------------>
      <v-row v-else class="col-quinta-pj mt-n2 mb-7">

        <v-col cols="4" class="d-none d-sm-flex"></v-col>

        <v-col cols="12" sm="4" class="pt-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.pessoa_cnae"
          :readonly="store_Pessoa.acao == 'C'"
          class="mt-n2 mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Cnae"
          placeholder="Cnae"
          dense
          filled
          outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.pessoa_email"
          :readonly="store_Pessoa.acao == 'C'"
          class="mt-n2 mb-0"
          light
          loader-height="1"
          hint="Por exemplo: meunome@meuservidor.com.br"
          background-color="#FFF"
          label="Email"
          placeholder="Digite o seu email"
          filled
          outlined
          :rules="emailRules"
          dense
          ></v-text-field>
        </v-col>

        <!-- <v-col cols="12" sm="4" class="pt-0 px-2">
          <pre>{{store_Pessoa.pessoa_edicao}}</pre>
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.ramo_negocio"
          :readonly="store_Pessoa.acao == 'C'"
          class="mt-n2"
          light
          loader-height="1"
          background-color="#FFF"
          label="Ramo Negócio"
          placeholder="Ramo Negócio"
          filled
          outlined
          dense
          ></v-text-field>
        </v-col> -->
      </v-row>
    <!-- QUINTA COLUNA / PJ ------------------------>

    <!---- TELEFONES / PJ ----------------------------------->
      <v-row v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Jurídica'" class="telefones mt-n11 mb-3 pt-0 px-0">
        <v-col cols="12" sm="3" class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.fone_residencial"
          :readonly="store_Pessoa.acao == 'C'"
          v-mask="MASK_TELEFONE"
          class="mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Telefone Residencial"
          placeholder="(xx) xxxx-xxxx"
          filled
          outlined
          dense
          :rules="foneRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.fone_comercial1"
          :readonly="store_Pessoa.acao == 'C'"
          v-mask="MASK_TELEFONE"
          class="mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Telefone Comercial"
          placeholder="(xx) xxxx-xxxx"
          filled
          outlined
          dense
          :rules="foneRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.fone_celular1"
          :readonly="store_Pessoa.acao == 'C'"
          v-mask="MASK_TELEFONE"
          class="mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Celular 1"
          placeholder="(xx) xxxxx-xxxx"
          filled
          outlined
          dense
          :rules="celRules"
          ></v-text-field>
        </v-col>  

        <v-col cols="12" sm="3" class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.fone_celular2"
          :readonly="store_Pessoa.acao == 'C'"
          v-mask="MASK_TELEFONE"
          class="mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Celular 2"
          placeholder="(xx) xxxxx-xxxx"
          filled
          outlined
          dense
          :rules="celRules"
          ></v-text-field>
        </v-col>
      </v-row>
    <!---- FIM TELEFONES / PJ ----------------------------------->

    <!---- TELEFONES / PF ----------------------------------->
      <v-row v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" class="telefones mt-n3 mb-3 pt-0 px-0">
        <v-col cols="12" sm="3" class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.fone_residencial"
          :readonly="store_Pessoa.acao == 'C'"
          v-mask="MASK_TELEFONE"
          class="mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Telefone Residencial"
          placeholder="(xx) xxxx-xxxx"
          filled
          outlined
          dense
          :rules="foneRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.fone_comercial1"
          :readonly="store_Pessoa.acao == 'C'"
          v-mask="MASK_TELEFONE"
          class="mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Telefone Comercial"
          placeholder="(xx) xxxx-xxxx"
          filled
          outlined
          dense
          :rules="foneRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.fone_celular1"
          :readonly="store_Pessoa.acao == 'C'"
          v-mask="MASK_TELEFONE"
          class="mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Celular 1"
          placeholder="(xx) xxxxx-xxxx"
          filled
          outlined
          dense
          :rules="celRules"
          ></v-text-field>
        </v-col>  

        <v-col cols="12" sm="3" class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.fone_celular2"
          :readonly="store_Pessoa.acao == 'C'"
          v-mask="MASK_TELEFONE"
          class="mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Celular 2"
          placeholder="(xx) xxxxx-xxxx"
          filled
          outlined
          dense
          :rules="celRules"
          ></v-text-field>
        </v-col>
      </v-row>
    <!---- FIM TELEFONES / PF ----------------------------------->

    <!---- ÚLTIMA COLUNA / PF ----------------------------------->
      <v-row v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" class="mt-n3 mb-n4 px-0">
        <v-col cols="12" sm="3" class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.pessoa_profissao"
          :readonly="store_Pessoa.acao == 'C'"
          class="mt-n2 mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Profissão"
          placeholder="Profissão"
          dense
          filled
          outlined
          :rules="[rules.max(60, store_Pessoa.pessoa_edicao.pessoa_profissao)]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_edicao.pessoa_email"
          :readonly="store_Pessoa.acao == 'C'"
          class="mt-n2 mb-0"
          light
          loader-height="1"
          hint="Por exemplo: meunome@meuservidor.com.br"
          background-color="#FFF"
          label="Email"
          placeholder="Digite o seu email"
          filled
          outlined
          :rules="emailRules"
          dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" class="py-0 px-2">
          <v-select v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'"
          v-model="store_Pessoa.pessoa_edicao.pessoa_estadocivil"
          :readonly="store_Pessoa.acao == 'C'"
          class="mt-n2 mb-10 obrigatorio"
          label="Estado Civil"
          filled
          outlined
          dense
          background-color="map-deep-merge white"
          :items="arrayEstadoCivil"
          item-text="name"
          :rules="estadocivilRules"
          ></v-select>
        </v-col>  
      </v-row>
    <!---- ÚLTIMA COLUNA / PF ----------------------------------->
    <!----------- CONJUGE --------------------->
      <v-row v-if="(store_Pessoa.pessoa_edicao.pessoa_estadocivil == 'Casado(a)' || 
                    store_Pessoa.pessoa_edicao.pessoa_estadocivil == 'União Estável(a)') && 
                   store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'"
              class="mb-12">
        <fieldset class="mt-n10 mb-6 mx-2 d-flex flex-column rounded pt-4 pb-1" style="width: 100%; border: 1px solid #c4c4c4">

          <legend class="ml-3 mb-5 font-weight-medium body-2" :style="{ 'color':COR_SUBTITULO }"> Cônjuge </legend>

          <v-row class="mx-0 px-2">

            <!-- Nome -->
            <v-col cols="12" sm="12" class="py-0 px-2">
              <v-text-field
                v-model="conjuge.pessoa_nome"
                readonly
                class="input-conjuge mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Nome"
                placeholder="Nome"
                filled
                outlined
                :rules="nomeConjugeRules"
                required
                dense
              >
                <template v-slot:append-outer>
                  <v-icon
                  v-if="conjuge.cod_pessoa"
                  @click="store_Pessoa.dialogConjugeExcluir = true"
                  :disabled="store_Pessoa.acao == 'C'"
                  class="input-conjuge mb-0"
                  color="primary">
                  mdi-delete
                  </v-icon>
                  <v-icon
                  @click="store_Pessoa.dialogConjuge = true"
                  :disabled="store_Pessoa.acao == 'C'"
                  class="ml-2"
                  color="primary">
                  mdi-magnify-plus
                  </v-icon>
                </template>
              <!-- </v-autocomplete> -->
              </v-text-field>
            </v-col>

            <!-- CPF -->
            <v-col
              cols="12"
              :sm="isMobile ? 12 : 4"
              class="py-0 px-2">
              <v-text-field
                v-model="conjuge.pessoa_cpf"
                v-mask="MASK_CPFCNPJ"
                class="input-conjuge mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="CPF"
                placeholder="CPF"
                filled
                outlined
                readonly
                dense
              ></v-text-field>
            </v-col>

            <!-- Nacionalidade -->
            <v-col 
              cols="12"
              :sm="isMobile ? 12 : 4"
              :lg="isMobile ? 12 : 4"
              class="py-0 px-2">
              <v-autocomplete
                v-model="conjuge.pessoa_nacionalidade"
                label="Nacionalidade"
                class="input-conjuge mb-0"
                filled
                outlined
                readonly
                dense
                background-color="#FFF"
                :items="arrayPaises"
                item-text="nome"
              ></v-autocomplete>
            </v-col>

            <!-- Data de Nascimento -->
            <v-col cols="12"
              :sm="isMobile ? 12 : 4"
              class="py-0 px-2">
              <DatePicker
                v-model="conjuge.pessoa_nasc_dt" 
                label="Nascimento"
                placeholder="dia/mês/ano"
                class="input-conjuge mb-0"
                outlined
                backgroundColor="#FFF"
                tabindex="0"
                dense
                :rules="dataRules"
                format="DD/MM/YYYY"
                readonly
              ></DatePicker>
            </v-col>
          </v-row>

          <v-row class="mx-0 px-2">
            <!-- Regime de Bens -->
            <v-col cols="12" sm="12" class="py-0 px-2">              
              <v-autocomplete
                v-model="store_Pessoa.pessoa_edicao.cod_regime"
                :disabled="store_Pessoa.acao == 'C'"
                class=""
                label="Regime de Bens"
                filled
                outlined
                dense
                clearable
                background-color="map-deep-merge white"
                :items="arrayRegimeBens"
                item-text="name"
                item-value="codigo"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="mx-0 px-2">

            <v-col cols="12" :sm="isMobile ? 12 : 4" class="py-0 px-2">
              <DatePicker
                v-model="store_Pessoa.pessoa_edicao.pessoa_casamento_dt" 
                label="Data do Casamento"
                placeholder="dia/mês/ano"
                class="input-conjuge mb-0"
                outlined
                backgroundColor="#FFF"
                tabindex="0"
                dense
                :rules="dataRules"
                format="DD/MM/YYYY"
                :readonly="store_Pessoa.acao == 'C'"
              ></DatePicker>
            </v-col>
        
            <v-col cols="12" :sm="isMobile ? 12 : 8" class="py-0 px-2">            
              <v-text-field
              v-model="conjuge.pessoa_profissao"
              class="input-conjuge mb-0"
              light
              loader-height="1"
              background-color="#FFF"
              label="Profissão"
              placeholder="Profissão"
              filled
              outlined
              readonly
              dense
              ></v-text-field>
            </v-col>
          </v-row> 

        </fieldset>
      </v-row>
    <!----------- FIM CONJUGE --------------------->

    </v-form>
  <!-- FIM FORM CADASTRO -->

    <!-- DIALOGO CONJUGE -------------------------------------------------------->
    <v-container v-if="store_Pessoa.dialogConjuge">      
      <v-dialog
        v-model="store_Pessoa.dialogConjuge"
        scrollable
        max-width="500px"
        max-height="600px"
      >
        <v-card>
          <!-- Cabecalho do Conjuge -->
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Conjuge
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="store_Pessoa.dialogConjuge = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do Conjuge -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaConjugeMobile
            class="mt-n2"
            :editar_="true"
            />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do Conjuge -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="store_Pessoa.dialogConjuge = false; store_Pessoa.acao_abas = false"
              class="mr-4 btn text-none"
              color="primary"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_selecionado"
              @click="selecionarConjuge(store_Pessoa.pessoa_selecionado)"
            >
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- FIM DIALOGO CONJUGE ---------------------------------------------------->

  <!-- TABELA ENDERECOS -------------------------------------------------------->
  <v-data-table
    v-if="store_Pessoa.acao == 'C'"
    class="rounded-lg mt-n3 tabela"
    flat
    :items="store_Pessoa.pessoa_edicao.pessoa_enderecos"
    :headers="headers_enderecos"
    :loading="loading"
    :items-per-page="10"
    hide-default-header
    hide-default-footer
    loading-text="Carregando...  aguarde..."
    no-data-text="Nenhum Registro Encontrado"
    no-results-text="Nenhum Registro Encontrado"
    >
      <template v-slot:top>
        <v-toolbar
        flat
        class="rounded-lg"
        >
          <p class="font-primary font-weight-bold body-1 text-center mt-1" :style="{ 'color':COR_SUBTITULO }">
            Endereços
          </p>  

          <v-spacer></v-spacer>

          <v-btn 
          @click="ModalEndereco()"
          class="btn pt-1 pr-6 Pessoa_DadosBasicos_alterar" elevation="0">
            <v-icon class="btn-icon-plus mr-1" color="green accent-4">mdi-plus</v-icon>
            <span class="font-weight-medium text-none" :style="{ 'color':COR_SUBTITULO }">Novo</span>
          </v-btn>
        </v-toolbar>
      </template>

      <!-- Titulos da Grade Endereço -->
      <template v-slot:header v-if="!isMobile">
          <thead>
            <tr>
              <th v-for="h in headers_enderecos" :key="h.value" :class="h.class" >
                <span>{{h.text}}</span>
              </th>
            </tr>
          </thead>
      </template>

      <!-- Dados da Grade Endereço -->
      <template #item="{ item }">
        <tr style="padding-top: 10px" v-if="!isMobile">
          <td>
            {{ item.pessoa_endereco_logradouro}}
          </td>

          <td>
            {{ item.pessoa_endereco_numero}}
          </td>

          <td>
            {{ item.pessoa_endereco_complemento}}
          </td>

          <td style="width: 25%">
            {{ item.pessoa_endereco_bairro }}
          </td>

          <td style="width: 11%">
            {{ item.pessoa_endereco_cep}}
          </td>

          <td style="width: 30%">
            {{ item.pessoa_endereco_municipio.nome }} - {{ item.pessoa_endereco_municipio.uf }}
          </td>

          <td class="d-flex justify-end align-center" style="height: 70px !important">
            <v-icon v-if="item.pessoa_endereco_corresp == 'S'"
                    class="icon-action mr-3"> mdi-mailbox-open
            </v-icon>

            <v-btn
              @click="ModalEndereco(item)"
              icon
              dark
              class="btn-icon mr-3"
              color="grey">
              <v-icon class="icon-action"> mdi-pencil </v-icon>
            </v-btn>

            <v-btn
              @click="dialog_excluir_tipo = 'ENDERECOS'; dialog_excluir_registro = item; dialog_excluir = true"
              icon
              dark
              class="btn-icon"
              color="grey">
              <v-icon class="icon-action"> mdi-delete </v-icon>
            </v-btn>
          </td>
        </tr> 

        <tr style="width: 100%; padding-top: 10px;" v-if="isMobile">
          <td style="border-bottom: none;">
            <div style="font-size: 15px; display:block;" class="font-weight-bold">{{ item.pessoa_endereco_logradouro }}</div>
            <div style="font-size: 12px; color:#808080 !important;" class="mt-1" >{{ item.pessoa_endereco_numero }}</div>
            <div style="font-size: 12px; color:#808080 !important;" class="mt-1 mb-1" >{{ item.pessoa_endereco_bairro }}</div>
            <div style="font-size: 12px; color:#808080 !important;" class="mb-1 mt-1" >{{ item.pessoa_endereco_cep }}</div>
            <div style="font-size: 12px; color:#808080 !important;" class="mb-1 mt-1" >{{ item.pessoa_endereco_municipio.nome }} - 
                                                                                       {{ item.pessoa_endereco_municipio.uf }}</div> 
         
          
          </td>

          <td class="d-flex justify-end align-center">
            <v-icon v-if="item.pessoa_endereco_corresp == 'S'"
                    class="icon-action mr-3"> mdi-mailbox-open
            </v-icon>

            <v-btn
              @click="ModalEndereco(item)"
              icon
              dark
              class="btn-icon mr-3"
              color="grey">
              <v-icon class="icon-action"> mdi-pencil </v-icon>
            </v-btn>

            <v-btn
              @click="dialog_excluir_tipo = 'ENDERECOS'; dialog_excluir_registro = item; dialog_excluir = true"
              icon
              dark
              class="btn-icon"
              color="grey">
              <v-icon class="icon-action"> mdi-delete </v-icon>
            </v-btn>
          </td>
          
        </tr>
      </template>
    </v-data-table>

    <!-- Dialogo Inclusão de Enderecos  -->
    <v-container
      v-if="store_Pessoa.dialogEnderecosCadastro">
      <v-dialog
        v-model="store_Pessoa.dialogEnderecosCadastro"
        transition="dialog-bottom-transition"
        max-width="400"
        class="pa-0"
      >
        <ModalEnderecosCadastro 
          :dados_modal_endereco.sync="dados_modal_endereco"
          :dados_modal_municipio.sync="this.arrayMunicipios" />
      </v-dialog>
    </v-container>  
  <!-- FIM TABELA ENDERECOS -------------------------------------------------------->

  <!-- TABELA CONTATOS -------------------------------------------------------->
  <v-data-table
    v-if="store_Pessoa.acao == 'C'"
    class="rounded-lg mt-2 tabela"
    flat
    :items="store_Pessoa.pessoa_edicao.pessoa_contatos"
    :headers="headers_contatos"
    :loading="loading"
    :items-per-page="10"
    hide-default-header
    hide-default-footer
    loading-text="Carregando...  aguarde..."
    no-data-text="Nenhum Registro Encontrado"
    no-results-text="Nenhum Registro Encontrado"
    >
      <template v-slot:top>
        <v-toolbar
        flat
        class="rounded-lg"
        >
          <p class="font-primary font-weight-bold body-1 text-center mt-1" :style="{ 'color':COR_SUBTITULO }">
            Contatos
          </p>  

          <v-spacer></v-spacer>

          <v-btn 
          @click="ModalContato()"
          class="btn pr-6 Pessoa_DadosBasicos_alterar" elevation="0">
            <v-icon class="btn-icon-plus mr-1" color="green accent-4">mdi-plus</v-icon>
            <span class="font-weight-medium text-none" :style="{ 'color':COR_SUBTITULO }">Novo</span>
          </v-btn>
        </v-toolbar>
      </template>

      <!-- Titulos da Grade -->
      <template v-slot:header v-if="!isMobile">
          <thead>
            <tr>
              <th v-for="h in headers_contatos" :key="h.value" :class="h.class">
                <span>{{h.text}}</span>
              </th>
            </tr>
          </thead>
      </template>

      <!-- Dados da Grade -->
      <template #item="{ item }">
        <tr v-if="!isMobile">
          <td style="width: 40%">
            {{ item.pessoa_contato_nome}}
          </td>

          <td style="width: 20%">
            {{ item.pessoa_contato_fone1 }}
          </td>

          <td style="width: 20%">
            {{ item.pessoa_contato_fone2 }}
          </td>

          <td style="width: 20%">
            {{ item.pessoa_contato_fone3 }}
          </td>

          <td class="d-flex justify-end align-center" style="height: 65px!important">
            <v-btn
              @click="ModalContato(item)"
              icon
              dark
              class="btn-icon mr-3"
              color="grey">
              <v-icon class="icon-action"> mdi-pencil </v-icon>
            </v-btn>

            <v-btn
              @click="dialog_excluir_tipo = 'CONTATOS'; dialog_excluir_registro = item; dialog_excluir = true"
              icon
              dark
              class="btn-icon"
              color="grey">
              <v-icon class="icon-action"> mdi-delete </v-icon>
            </v-btn>
          </td>
          
        </tr> 
        <!-- MOBILE -->
        <tr v-if="isMobile">
          <td style="border-bottom: none;">
            <div style="font-size: 15px; display:block;"            class="font-weight-bold">{{ item.pessoa_contato_nome }}</div>
            <div style="font-size: 12px; color:#808080 !important;" class="mt-1" >{{ item.pessoa_contato_fone1 }}</div>
            <div style="font-size: 12px; color:#808080 !important;" class="mt-1 mb-1" >{{ item.pessoa_contato_fone2 }}</div>
            <div style="font-size: 12px; color:#808080 !important;" class="mb-1 mt-1" >{{ item.pessoa_contato_fone3 }}</div>
            
          </td>
      
          <td class="d-flex justify-end align-center">
            <v-btn
              @click="ModalContato(item)"
              icon
              dark
              class="btn-icon mr-3"
              color="grey">
              <v-icon class="icon-action"> mdi-pencil </v-icon>
            </v-btn>

            <v-btn
              @click="dialog_excluir_tipo = 'CONTATOS'; dialog_excluir_registro = item; dialog_excluir = true"
              icon
              dark
              class="btn-icon"
              color="grey">
              <v-icon class="icon-action"> mdi-delete </v-icon>
            </v-btn>
          </td>
          
        </tr> 
      </template>
    </v-data-table>
    <!-- FIM MOBILE -->

    <!-- Dialogo Inclusão Novo  -->
    <v-container v-if="store_Pessoa.dialogContatosCadastro">
      <v-dialog
        v-model="store_Pessoa.dialogContatosCadastro"
        transition="dialog-bottom-transition"
        max-width="400"
        class="pa-0"
      >
        <ModalContatosCadastro 
          :dados_modal_contato.sync="dados_modal_contato" />
      </v-dialog>
    </v-container>
  <!-- FIM TABELA CONTATOS -------------------------------------------------------->

  <!-- TABELA DADOS BANCARIOS -------------------------------------------------------->
  <!-- <pre>{{ 
  store_Pessoa.pessoa_edicao.pessoa_contas
  }}</pre> -->
    <v-data-table
      v-if="store_Pessoa.acao == 'C'"
      class="mt-3 rounded-lg tabela"
      :items="store_Pessoa.pessoa_edicao.pessoa_contas"
      :headers="headers_dados_bancarios"
      :loading="loading"
      :items-per-page="10"
      hide-default-header
      hide-default-footer
      loading-text="Carregando...  aguarde..."
      no-data-text="Nenhum Registro Encontrado"
      no-results-text="Nenhum Registro Encontrado"
    >
      <template v-slot:top>
        <v-toolbar
        flat
        class="rounded-lg"
        >
          <p
            class="font-primary font-weight-bold font-weight-medium body-1 text-center mt-2"
            :style="{ 'color':COR_SUBTITULO }">
            Dados Bancários
          </p>  
          
          <v-spacer></v-spacer>

          <v-btn 
          @click="ModalBancario()"
          class="btn pr-6 Pessoa_DadosBasicos_alterar" elevation="0">
            <v-icon class="btn-icon-plus mr-1" color="green accent-4">mdi-plus</v-icon>
            <span class="font-weight-medium text-none" :style="{ 'color':COR_SUBTITULO }">Novo</span>
          </v-btn>

        </v-toolbar>
      </template>

      <template v-slot:header v-if="!isMobile">
          <thead>
            <tr>
              <th v-for="h in headers_dados_bancarios" :key="h.value" :class="h.class">
                <span>{{h.text}}</span>
              </th>
            </tr>
          </thead>
      </template>

      <template #item="{ item }">
        <tr v-if="!isMobile">
          <td>{{ item.pessoa_conta_descricao }}</td>
          <td style="width: 15%">
            {{ item.pessoa_conta_titular_cpf }}
          </td>

          <td class="d-flex justify-end align-center" style="height: 70px!important">
            <v-btn
              @click="ModalBancario(item)"
              icon
              dark
              class="btn-icon mr-3"
              color="grey">
              <v-icon class="icon-action"> mdi-pencil </v-icon>
            </v-btn>

            <v-btn
              @click="dialog_excluir_tipo = 'CONTAS'; dialog_excluir_registro = item; dialog_excluir = true"
              icon
              dark
              class="btn-icon"
              color="grey">
              <v-icon class="icon-action"> mdi-delete </v-icon>
            </v-btn>
          </td>

        </tr>

         <!-- MOBILE  -->
        <tr v-if="isMobile">
          <td style="width: 100%; padding-top: 10px; border-bottom: none;">
            <div style="font-size: 15px; display:block;"            class="font-weight-bold">{{ item.pessoa_conta_agencia.banco_agencia.banco_nome }}</div>
            <div style="font-size: 12px; color:#808080 !important;" class="mt-1" >{{ item.pessoa_conta_agencia.agencia_numero }}-{{ item.pessoa_conta_agencia.agencia_numero_digito }}</div>
            <div style="font-size: 12px; color:#808080 !important;" class="mt-1 mb-1" >{{ item.pessoa_conta_numero }}</div>
            <div style="font-size: 12px; color:#808080 !important;" class="mb-1 mt-1" >{{ item.pessoa_conta_titular }}</div>
            <div style="font-size: 12px; color:#808080 !important;" class="mb-1 mt-1" > {{ item.pessoa_conta_titular_cpf }}</div>
          </td>

          <td class="d-flex justify-end align-center">
            <v-btn
              @click="ModalBancario(item)"
              icon
              dark
              class="btn-icon mr-3"
              color="grey">
              <v-icon class="icon-action"> mdi-pencil </v-icon>
            </v-btn>

            <v-btn
              @click="dialog_excluir_tipo = 'CONTAS'; dialog_excluir_registro = item; dialog_excluir = true"
              icon
              dark
              class="btn-icon"
              color="grey">
              <v-icon class="icon-action"> mdi-delete </v-icon>
            </v-btn>
          </td>

        </tr>
        <!-- FIM DO MOBILE -->
      </template>
    </v-data-table>

    <!-- Dialogo Inclusão Novo  -->
    <v-container v-if="store_Pessoa.dialogDadosBancariosCadastro">
      <v-dialog
        v-model="store_Pessoa.dialogDadosBancariosCadastro"
        transition="dialog-bottom-transition"
        max-width="400"
        class="pa-0"
      >
      <ModalDadosBancariosCadastro
        :dados_modal_bancario.sync="dados_modal_bancario"
        :dados_modal_bancario_bancos.sync="dados_modal_bancario_bancos"
        :contas="store_Pessoa.pessoa_edicao.pessoa_contas"
        :nomeTitular="store_Pessoa.pessoa_edicao.pessoa_nome" />
      </v-dialog>
    </v-container>

  <!-- FIM TABELA BANCARIOS -------------------------------------------------------->

<!-- ============================================================================================================================================================
============================================================================================================================================================ -->
<!-- socios <pre>{{store_Pessoa.pessoa_edicao.pessoa_socios1}}</pre> -->
  <!-- TABELA SOCIOS -------------------------------------------------------->
    <v-data-table
      v-if="store_Pessoa.acao == 'C' && store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Jurídica'"
      class="rounded-lg tabela mt-3"
      flat
      :items="store_Pessoa.pessoa_edicao.pessoa_socios1"
      :headers="headers_socios"
      :loading="loading"
      :items-per-page="10"
      show-select
      hide-default-header
      hide-default-footer
      loading-text="Carregando...  aguarde..."
      no-data-text="Nenhum Registro Encontrado"
      no-results-text="Nenhum Registro Encontrado"
    >
      <template v-slot:top>
        <v-toolbar
        flat
        class="rounded-lg"
        >
          <p class="font-primary font-weight-bold font-weight-medium body-1 text-center mt-2" :style="{ 'color':COR_SUBTITULO }">
            Sócios
          </p>  

          <v-spacer></v-spacer>

          <v-btn 
          @click="store_Pessoa.dialogSociosCadastro = true"
          class="btn pr-6" elevation="0">
            <v-icon class="btn-icon-plus mr-1" color="green accent-4">mdi-plus</v-icon>
            <span class="caption font-weight-medium" :style="{ 'color':COR_SUBTITULO }">Novo</span>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:header v-if="!isMobile">
          <thead>
            <tr>
              <th v-for="h in headers_socios" :key="h.value" :class="h.class">
                <span>{{h.text}}</span>
              </th>
            </tr>
          </thead>
      </template>

      <template #item="{ item }">
        <tr>
          <td width="100%" :style="isMobile ? 'border-bottom:none' : ''">
            <span :style="item.Pessoa_Socio.pessoa_socio_titular == 'S' ? 'font-weight: bold;' : ''" >  {{ item.pessoa_nome }}</span>
          </td>
          <td width="100%" :style="isMobile ? 'border-bottom:none' : ''">
            {{ item.Pessoa_Socio.pessoa_socio_porc }}%                  
          </td>
          <td class="d-flex  justify-end align-center">
            <v-btn
              @click="dialog_editar_socio = true; item_editar_socio_aux = item; editar_porc_aux = item.Pessoa_Socio.pessoa_socio_porc; select_socio_aux = item.Pessoa_Socio.pessoa_socio_titular"
              icon 
              dark 
              class="btn-icon mr-3" 
              color="grey">
              <v-icon class="icon-action"> mdi-pencil </v-icon>
            </v-btn>
              
            <v-btn
              @click="dialog_excluir_socio = true; item_excluir_socio_aux = item" 
              icon 
              dark 
              class="btn-icon mr-0" 
              color="grey">
              <v-icon class="icon-action"> mdi-delete </v-icon>
            </v-btn>
          </td>
        </tr> 
      </template>
    </v-data-table>

  <!-- FIM TABELA SOCIOS -------------------------------------------------------->
    <!-- Dialogo excluir socio -->
      <v-container
        v-if="dialog_excluir_socio">
        <v-dialog v-model="dialog_excluir_socio" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
            <v-divider></v-divider>
            <v-card elevation="0" class="">
              <v-card-title class="text-h6" style="word-break: keep-all">
                Tem certeza que deseja EXCLUIR este sócio?
              </v-card-title>
              <v-card-actions class="d-flex justify-center">
                <v-spacer></v-spacer>
                <v-btn class="mr-4" color="primary" text @click="dialog_excluir_socio = false">
                  Cancelar
                </v-btn>
                <v-btn 
                  class="btn white--text" 
                  color="primary accent-4" 
                  :loading="loading"
                  @click="excluirSocio()">
                  Excluir
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </v-container>
      <!-- Fim Dialogo excluir socio -->

      <!-- Dialogo Editar Socio -->
      <v-container
        v-if="dialog_editar_socio">
        <v-dialog
            v-model="dialog_editar_socio"
            scrollable
            max-width="500px"
            max-height="600px"
            persistent>
            <v-card>
              <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
                <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
                  <div class="pa-0" style="width:100%">
                    <p style="color:white; white-space: pre-line; font-size:16px !important;">
                      Alterar porcentagem 
                    </p>                      
                  </div>
                  <div class="pa-0 text-center" style="width:40px;">
                    <v-btn icon dark v-on:click="dialog_editar_socio = false; select_socio_aux = null" class="mt-n2">
                      <v-icon large class="title">mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-layout>
              </v-card-title>
              <v-card-text >
                <v-row>
                  <v-col 
                    cols="12"
                    sm="12"
                    class="mb-n4"
                    style="margin-top: 50px">
                    <v-text-field
                      v-model="editar_porc_aux"
                      class="input-inscricao mb-0"
                      background-color="#FFF"
                      label="Porcentagem"
                      placeholder="Porcentagem"
                      filled
                      outlined
                      dense
                      :rules="porcentagemRules">
                    </v-text-field>
                  </v-col>
                  <v-col 
                    cols="12"
                    sm="12"
                    class="mt-n2 mb-n2">
                    <v-select
                      v-model="select_socio_aux"
                      :items="arraySIM_NAO"
                      placeholder="Titular?"
                      label="Titular?"
                      item-text="name"
                      item-value="value"
                      required
                      outlined
                      dense
                      :rules="titularRules">
                    </v-select>
                </v-col>
              </v-row>
              </v-card-text>
              <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
                <v-btn
                  @click="dialog_editar_socio = false; select_socio_aux = null"
                  class="mr-4 btn text-none"
                  color="primary"
                  text>
                  Cancelar
                </v-btn>
                <v-btn
                  :loading="loading"
                  @click="editarSocio()"
                  class="btn text-none"
                  color="primary"
                  :disabled="!store_Pessoa.pessoa_conjuge_selecionado">
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </v-container>
      <!-- Fim Dialog Editar Socio -->

    <v-footer 
      v-if="store_Pessoa.acao != 'C'"
      color="transparent"
      elevation="0"
      class="footer justify-center mt-3 pt-3"
    >
      <v-btn
        @click="cancelar()"
        id="btn_cancelar"
        ref="btn_cancelar"
        class="mr-4 caption font-weight-medium"
        color="primary"
        text
      >
        Cancelar
      </v-btn>

      <v-btn
      :disabled="!valid"
      @click="validate()"
      :loading="loading"
      class="btn caption font-weight-medium white--text"
      color="primary accent-4"
      >
        Salvar
      </v-btn>
    </v-footer>

    <!-- Arquivo/Webcam Dialog ------------------------------------------ -->
    <v-container v-if="dialog_crop">
      <v-dialog
        v-if="dialog_crop"
        :value="true"
        persistent
        style="width: 330"
        :max-width="330"
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>Foto</v-card-title>
          <v-card-text class="pt-7">
            <VueCropImage
              :img="store_Pessoa.pessoa_edicao.pessoa_foto"
              :imgsync.sync="store_Pessoa.pessoa_edicao.pessoa_foto"
              :dialog_crop.sync="dialog_crop"
              ref="VueCropImage"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
      </v-container>
    <!-- --------------------------------------------------- -->

    <!-- Dialogo Padrão de Exclusão das Tabelas Relationadas -->
    <v-container v-if="store_Pessoa.dialogConjugeExcluir">
      <v-dialog
        v-model="store_Pessoa.dialogConjugeExcluir"
        transition="dialog-bottom-transition"
        persistent
        max-width="410"
        class="pa-0"
      >
        <v-divider></v-divider>

        <v-card elevation="0" class="">
        <v-card-title class="text-h6" style="word-break: keep-all">
          Tem certeza que deseja LIMPAR os dados do Cônjuge?
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
            <v-btn
              class="mr-4"
              color="primary"
              text
              @click="store_Pessoa.dialogConjugeExcluir = false; store_Pessoa.acao_abas = false"
            >
              Cancelar
            </v-btn>

            <v-btn
              class="btn white--text"
              color="primary accent-4"
              :loading="loading_excluir"
              @click="selecionarConjuge();"
            >
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Dialogo Padrão de Exclusão das Tabelas Relacionadas -->
    <v-container v-if="dialog_excluir">
      <v-dialog
        v-model="dialog_excluir"
        transition="dialog-bottom-transition"
        persistent
        max-width="410"
        class="pa-0"
      >
          <v-divider></v-divider>

          <v-card elevation="0" class="">
          <v-card-title class="text-h6" style="word-break: keep-all">
            Tem certeza que deseja EXCLUIR este registro?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
              <v-btn
                class="mr-4"
                color="primary"
                text
                @click="store_Pessoa.acao_abas = false; dialog_excluir = false; dialog_excluir_tipo = null; dialog_excluir_registro = null"
              >
                Cancelar
              </v-btn>

              <v-btn
                class="btn white--text"
                color="primary accent-4"
                :loading="loading_excluir"
                @click="excluir_relacionados(dialog_excluir_tipo, dialog_excluir_registro)"
              >
                Excluir
              </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>  

    <!-- DIALOGO SOCIO -------------------------------------------------------->
    <v-container v-if="store_Pessoa.dialogSociosCadastro">      
      <v-dialog
        v-model="store_Pessoa.dialogSociosCadastro"
        scrollable
        max-width="500px"
        max-height="600px"
      >
        <v-card>
          <!-- Cabecalho do Sociio -->
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Sócio
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="store_Pessoa.dialogSociosCadastro = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do Socio -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaSocioMobile
              class="mt-n2"
              :editar_="true"
            />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do Socio -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="store_Pessoa.dialogSociosCadastro = false; store_Pessoa.acao_abas = false"
              class="mr-4 btn text-none"
              color="primary"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_selecionado"
              @click="dialog_titular = true"
            >
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- FIM DIALOGO SOCIO ---------------------------------------------------->

       <!-- Dialogo adicionar Socio -->
    <v-container
      v-if="dialog_titular">
      <v-dialog
          v-model="dialog_titular"
          scrollable
          max-width="500px"
          max-height="600px"
          persistent>
          <v-card>
            <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
              <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
                <div class="pa-0" style="width:100%">
                  <p style="color:white; white-space: pre-line; font-size:16px !important;">
                    Titular
                  </p>                      
                </div>
                <div class="pa-0 text-center" style="width:40px;">
                  <v-btn icon dark v-on:click="dialog_titular = false; select_socio_aux = null" class="mt-n2">
                    <v-icon large class="title">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-layout>
            </v-card-title>
            <v-card-text >
              <v-form
                v-model="validAdicionar"
                ref="formAdicionar"
                class="mx-1 mt-3">
                <v-row>
                  <v-col 
                  cols="12"
                    sm="12"
                    class="mb-n4"
                    style="margin-top: 50px">
                    <v-text-field
                      v-model="editar_porc_aux"
                      class="input-inscricao mb-0"
                      background-color="#FFF"
                      label="Porcentagem"
                      placeholder="Porcentagem"
                      filled
                      outlined
                      dense
                      :rules="porcentagemRules">
                    </v-text-field>
                  </v-col>
                  <v-col 
                    cols="12"
                    sm="12"
                    class="mt-n2 mb-n2">
                    <v-select
                      v-model="select_socio_aux"
                      :items="arraySIM_NAO"
                      placeholder="Titular?"
                      label="Titular?"
                      item-text="name"
                      item-value="value"
                      required
                      outlined
                      dense
                      :rules="titularRules">
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
              <v-btn
                @click="dialog_titular = false; select_socio_aux = null"
                class="mr-4 btn text-none"
                color="primary"
                text>
                Cancelar
              </v-btn>
              <v-btn
                :loading="loading"
                @click="adicionarSocio(store_Pessoa.pessoa_conjuge_selecionado); dialog_titular = false"
                class="btn text-none"
                color="primary"
                :disabled="!validAdicionar">
                Selecionar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
    <!-- Fim Dialogo adicionar Socio -->

  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import store_Pessoa from "./store_Pessoa";
import store_site from "../../store/store_site";
import store_usuario from "../../store/store_usuario";
import {
  formatDate,
  maskCpfCnpj,
  CpfCnpjIsValid,
  emailIsValid,
  telefoneIsValid,
} from "../../services/funcoes";
import { arraySIM_NAO, COR_PRINCIPAL, COR_SUBTITULO, COR_SECUNDARIA, MASK_CPFCNPJ, MASK_TELEFONE, arraySexo, arrayEstadoCivil, arrayPaises, arrayRegimeBens } from "../../services/constantes";
import { nomeIniciais } from "../../services/funcoes"
import { API } from "../../services/API"
import { BancosGet } from "../../services/global";
import DatePicker from "../ComponentesGlobais/Date-Picker/date-picker.vue";
import VueCropImage from "../ComponentesGlobais/CropImage/VueCropImage.vue";
import PessoaConsultaConjugeMobile from "./PessoaConsultaConjugeMobile.vue"
import PessoaConsultaSocioMobile from "./PessoaConsultaSocioMobile.vue"
import ModalEnderecosCadastro from "./ModalEnderecosCadastro.vue";
import ModalDadosBancariosCadastro from "./ModalDadosBancariosCadastro.vue";
import ModalContatosCadastro from "./ModalContatosCadastro.vue";

export default {
  name: "Pessoa_DadosBasicos",

  components: {
    VueCropImage,
    DatePicker,
    PessoaConsultaConjugeMobile,
    ModalEnderecosCadastro,
    ModalContatosCadastro,
    ModalDadosBancariosCadastro,
    PessoaConsultaSocioMobile,
  },

  data() {
    return {
      store_Pessoa  : store_Pessoa,
      store_site    : store_site,
      store_usuario : store_usuario,

      maskCpfCnpj   : maskCpfCnpj,
      formatDate    : formatDate,
      nomeIniciais  : nomeIniciais,

      img                     : null,
      imgsync                 : null,
      dialog_crop             : false,
      dialog_webcam           : false,
      dialog_excluir_tipo     : false,
      dialog_excluir          : false,
      dialog_excluir_registro : false,
      dialog_excluir_socio    : false,
      loading_excluir         : false,
      dialog_titular          : false,
      dialog_editar_socio     : false,
      editar_porc_aux         : null,
      select_socio_aux        : null,
      key_cnpj                : 0,

      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_TELEFONE     : MASK_TELEFONE,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,  
      COR_SECUNDARIA    : COR_SECUNDARIA,
      arraySexo         : arraySexo,
      arrayEstadoCivil  : arrayEstadoCivil,
      arrayPaises       : arrayPaises,
      arrayMunicipios   : null,
      arrayRegimeBens   : arrayRegimeBens,
      arraySIM_NAO           : arraySIM_NAO,

      isMobile          : false,
      search            : null,

      pessoa_edicaoAux            : null,
      dados_modal_endereco        : {},
      dados_modal_contato         : {},
      dados_modal_bancario        : {cod_pessoa: null, cod_banco: null, acao: null},
      dados_modal_bancario_bancos : [],
      
    /* Campos */
      valid                 : true,
      validAdicionar        : false,
      loading               : false,

      conjuge               : { cod_pessoa       : null,
                                pessoa_nome      : null,
                                pessoa_cpf       : null,
                                pessoa_nasc_dt   : null,
                                pessoa_profissao : null
                              },

    /* Título Tabela Endereços */
      headers_enderecos: [
        { text: "Logradouro", value: "logradouro", class: 'title-text'},
        { text: "N", value: "N", class: 'title-text', },
        { text: "Compl", value: "Compl", class: 'title-text', },
        { text: "Bairro", value: "Bairro", class: 'title-text', },
        { text: "Cep", value: "Cep", class: 'title-text', },
        { text: "Cidade", value: "Cidade", class: 'title-text', },
        { text: ""},
      ],

    /* Títulos Tabela Contatos */
      headers_contatos: [
        { text: "Nome contato", value: "Nome contato", class: 'title-text' },
        { text: "Telefone 01", value: "Telefone 01", class: 'title-text' },
        { text: "Telefone 02", value: "Telefone 02", class: 'title-text' },
        { text: "Telefone 03", value: "Telefone 03", class: 'title-text' },
        { text: ""},        
      ],

    /* Títulos Tabela Dados Bancários */
      headers_dados_bancarios: [
        // { text: "Banco", value: "Banco", class: 'title-text' },
        // { text: "Agência", value: "Agência", class: 'title-text' },
        // { text: "Conta", value: "Conta", class: 'title-text' },
        { text: "Dados", value: "Dados", class: 'title-text' },
        { text: "CPF/CNPJ", value: "CPF/CNPJ", class: 'title-text' },
        { text: ""},
      ],

    /* Tabela Dados Bancarios */
      dados_bancarios: [
        {banco: "Bradesco", agencia: "18009-23", conta: "00000000", titular: "Fernando Gracia", cpf_cnpj_titular: "000.000.000-00"},
      ],

    /* Títulos Tabela Sócios*/
      headers_socios: [
        { text: "Sócio", value: "Sócio", class: 'title-text' },
        { text: "Porcentagem", value: "Porcentagem", class: 'title-text' },
        { text: "" },
      ],

    /* Tabela Sócios */
        socios: [
        {socio: "João Feliciano", porcentagem: "20%"},
      ],

      rules: {
        min(min, v) { 
          return (v || '').length >= min || `Mínimo de ${min} caracteres`;
        },
        max(max, v) { 
          return (v || '').length <= max || `Máximo de ${max} caracteres`;
        },
        data(v) { 
          return (v || '').length >= 10 || `Mínimo de 10 caracteres`;
        },
      },
    
      nameRules: [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O nome deve ter menos do que 60 caracteres",
      ],
      razaoRules: [
        (value) => !!value || "A Razão Social é obrigatória",
        (value) =>
          (value && value.length >= 3) ||
          "A Razão Social deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "A Razão Social deve ter menos do que 60 caracteres",
      ],

      sexoRules: [
        (value) => !!value || "O Sexo é obrigatório",
      ],
      emailRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && emailIsValid(value))) || "O e-mail não é válido",
        (value) =>
          (!value || value.length <= 100) || "O Email deve ter menos do que 100 caracteres",
      ],
      dataRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && moment(value, 'DD/MM/YYYY', true).isValid())) || "A Data não é válida",
        (value) =>
          (!value || value.length <= 10) || "A Data deve ter menos do que 10 caracteres",
      ],
      foneRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && telefoneIsValid(value))) || "O telefone não é válido",
      ],
      celRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && telefoneIsValid(value))) || "O celular não é válido",
      ],
      estadocivilRules: [
        (value) => !!value || "O Estado Civil é obrigatório",
      ],
      cpfRules: [
        (value) => !!value || "O CPF é obrigatório",
        (value) => (value && CpfCnpjIsValid(value)) || "O CPF não é válido",
        (value) =>
          (value && value.length <= 14) ||
          "O CPF deve ter 14 caracteres",
      ],
      cnpjRules: [
        (value) => !!value || "O CNPJ é obrigatório",
        (value) => (value && CpfCnpjIsValid(value)) || "O CNPJ não é válido",
        (value) =>
          (value && value.length <= 18) ||
          "O CNPJ deve ter 18 caracteres",
      ],
      porcentagemRules:[
        (value) => !!value || "A porcentagem é obrigatória",
        (value) =>
        (!value || value.length <= 3) || "A porcentagem deve ter até 3 caracteres",
        (value) => 
        ( !value ||  /^\d+$/.test(value)) || 'A porcentagem não é válida',
      ],
      titularRules:[
        (value) => !!value || "O titular é obrigatório",
      ],
      nomeConjugeRules: [
        //(value) => !!value || "O cônjuge é obrigatório",
      ],
    };
  },
  created() {
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
      this.isMobile = true;
    }
  },
  computed: {
    textMunicipio() {
      return this.store_Pessoa.pessoa_edicao?.pessoa_municipio?.nome ? 
      this.store_Pessoa.pessoa_edicao?.pessoa_municipio?.nome + " - " + this.store_Pessoa.pessoa_edicao?.pessoa_municipio?.uf : ''
    },
  },
   watch: {

    search(query) {
      // console.log(query);
      if (query && (!this.select || this.select.text !== query)) {
        this.querySearch(query);
      }
    },

    conjuge(){
      if(!!this.store_Pessoa?.pessoa_conjuge_selecionado?.pessoa_nome){
        this.conjuge = this.store_Pessoa.pessoa_conjuge_selecionado 
        // console.log('watch', !!this.store_Pessoa.pessoa_conjuge_selecionado.pessoa_nome);
      }
    }
  },
  async mounted() {
    
    // Seta os privilegios dos elementos da aplicação inteira
    this.store_usuario.priv_seta_privilegios_aplicacao(this.$options.name);

    this.municipios(); 
    
    var lo_params = { cod_pessoa: this.$route.params.cod_pessoa };
    if (lo_params.cod_pessoa) {
      this.store_Pessoa.acao = "C";
      const lo_Res = await this.store_Pessoa.PessoaGet(lo_params);
      console.log("lo_Res", lo_Res)
      if ( lo_Res.rows.length > 0) {
        this.store_Pessoa.pessoa_edicao = { ...lo_Res.rows[0] };
        if(!!this.store_Pessoa.pessoa_edicao.pessoa_conjuge){
          // console.log('entrei no if');
          this.conjuge = this.store_Pessoa.pessoa_edicao.pessoa_conjuge
          // console.log('conjuge do mobile',this.conjuge);
      // console.log('mounted');

        }
      }
    }
    // Inclusão ---------------------------------------------------
    else {
      this.store_Pessoa.acao = "I";
      this.store_Pessoa.pessoa_edicao = {pessoa_fisica_juridica : 'Física'};
    }   
  },

  destroyed() {
    this.pessoa_edicaoAux = null;
    this.selecionarConjuge();
  },

  methods: {
    async buscaDadosCnpj(cnpj){
      if(!!cnpj && cnpj.length == 18){
        let param = cnpj.replace(/[^\d]/g, '');
        const result = await axios.get(`https://brasilapi.com.br/api/cnpj/v1/${param}`);
        if(result.status == 200){
          this.objeto_dados_cnpj = result.data
          console.log("🚀 ~ file: PessoaCadastro.vue:2188 ~ buscaDadosCnpj ~ this.objeto_dados_cnpj:", this.objeto_dados_cnpj)

          this.store_Pessoa.pessoa_edicao.pessoa_nome = this.objeto_dados_cnpj.razao_social
          this.store_Pessoa.pessoa_edicao.pessoa_firma_abertura_dt = moment(this.objeto_dados_cnpj.data_inicio_atividade).format()
          this.store_Pessoa.pessoa_edicao.pessoa_cnae = this.objeto_dados_cnpj.cnae_fiscal
          this.store_Pessoa.pessoa_edicao.pessoa_email = this.objeto_dados_cnpj.email
          this.store_Pessoa.pessoa_edicao.fone_residencial = this.objeto_dados_cnpj.ddd_telefone_1
          this.store_Pessoa.pessoa_edicao.fone_comercial1 = this.objeto_dados_cnpj.ddd_telefone_2
          this.key_cnpj ++
        }else{
          this.store_site.alert_cor       = "#A00000";
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = 'Não foi encontrado este CNPJ!'
          this.store_site.alert           = true;
        }
      }
    },

    async excluirSocio(){
      // console.log("dados", this.store_Pessoa.pessoa_edicao.pessoa_socios1);
      // return

      // this.loading = true
      //Monta os dados para fazer a requisição
      let aux = {};
      aux.cod_pessoa_socio = this.item_excluir_socio_aux.Pessoa_Socio.cod_pessoa_socio
      aux.cod_pessoa_juridica = this.item_excluir_socio_aux.Pessoa_Socio.cod_pessoa_juridica;
      let lo_Socio = {...aux, acao: "D"};
      let ls_Socio = await this.store_Pessoa.PessoaSocioPost(lo_Socio)
      // console.log('ate aqui', ls_Socio);
      // return

        //Atualiza a tabela com os novos dados
      this.store_Pessoa.pessoa_edicao.pessoa_socios1 = this.store_Pessoa.pessoa_edicao.pessoa_socios1.filter(value =>
        value.Pessoa_Socio.cod_pessoa_socio != this.item_excluir_socio_aux.Pessoa_Socio.cod_pessoa_socio
      )

        //verifica se existe 1 socio como titular
      let seta_socio_como_titular = false
      if (this.store_Pessoa.pessoa_edicao.pessoa_socios1.length > 0) {
        for(let value of this.store_Pessoa.pessoa_edicao.pessoa_socios1){
          if(value.Pessoa_Socio.pessoa_socio_titular == 'S'){
            seta_socio_como_titular = false
          }
          else{
            seta_socio_como_titular = true
          }
        }
        // se não existe nenhum socio como titular, seta o primeiro
          // console.log('seta_socio_como_titular', seta_socio_como_titular);
        if (seta_socio_como_titular) {
          let titular = {}
          titular = this.store_Pessoa.pessoa_edicao.pessoa_socios1[0].Pessoa_Socio
          titular.pessoa_socio_titular = 'S'
          // console.log('titular', titular);
          let lo_socio_titular = {...titular, acao: "E"};

          // console.log("lo_socio_titular", lo_socio_titular);
          let ls_Socio = await this.store_Pessoa.PessoaSocioPost(lo_socio_titular)
          // console.log("ls_Socio", ls_Socio);
        }
      }

      //Retorno do backend
      if ((ls_Socio.result && ls_Socio.result.message && ls_Socio.result.message == 'success') ||
          (ls_Socio.status && ls_Socio.status == 200)) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Socio.result.result.trim();
          this.store_site.alert           = true;
        } else {
          this.store_site.alert_cor       = "#A00000";
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = ls_Socio.errors.trim();
          this.store_site.alert           = true;
        }

      this.loading = false;
      this.dialog_excluir_socio = false;
    },

    async editarSocio(){
      this.loading = true
      //Monta os dados para fazer a requisição
      let aux = {};
      aux.cod_pessoa_socio = this.item_editar_socio_aux.Pessoa_Socio.cod_pessoa_socio
      aux.cod_pessoa_juridica = this.item_editar_socio_aux.Pessoa_Socio.cod_pessoa_juridica;

      //Verifica se foi digitado a porcentagem
      if(!this.editar_porc_aux){
        aux.pessoa_socio_porc = 0
      }else{
        aux.pessoa_socio_porc = this.editar_porc_aux;
      }

      //Verifica se foi informado se é titular
      if(!this.select_socio_aux){
        aux.pessoa_socio_titular = "N"
      }else{
        aux.pessoa_socio_titular = this.select_socio_aux;
      }

      let lo_Socio = {...aux, acao: "E"};
      let ls_Socio = await this.store_Pessoa.PessoaSocioPost(lo_Socio)

      //Atualiza a tabela com os novos dados do titular
      for(let value of this.store_Pessoa.pessoa_edicao.pessoa_socios1){
        if(value.Pessoa_Socio.cod_pessoa_socio == this.item_editar_socio_aux.Pessoa_Socio.cod_pessoa_socio){
          value.Pessoa_Socio.pessoa_socio_porc = Number(this.editar_porc_aux);
          value.Pessoa_Socio.pessoa_socio_titular = this.select_socio_aux;
        }
      }

      //Atualiza o banco com os novos dados do titular
      let novo_lo_Socio
      let novo_ls_Socio
      if(this.item_editar_socio_aux.Pessoa_Socio.pessoa_socio_titular == "S"){
        for(let value of this.store_Pessoa.pessoa_edicao.pessoa_socios1){
          if(value.Pessoa_Socio.cod_pessoa_socio != this.item_editar_socio_aux.Pessoa_Socio.cod_pessoa_socio){
            value.Pessoa_Socio.pessoa_socio_titular = "N";
            let novoValue = value.Pessoa_Socio;
            novo_lo_Socio = {...novoValue, acao: "E"}
            novo_ls_Socio = await this.store_Pessoa.PessoaSocioPost(novo_lo_Socio)
          }
        }
      }

      //Retorno do backend
      if ((ls_Socio.result && ls_Socio.result.message && ls_Socio.result.message == 'success') ||
          (ls_Socio.status && ls_Socio.status == 200)) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Socio.result.result.trim();
          this.store_site.alert           = true;
        } else {
          this.store_site.alert_cor       = "#A00000";
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = ls_Socio.errors.trim();
          this.store_site.alert           = true;
        }
  
      this.loading = false
      this.dialog_editar_socio = false;
    },
    async adicionarSocio(){
      const lb_valido_adicionar = this.$refs.formAdicionar.validate();
      if(lb_valido_adicionar){
        this.loading = true

        // console.log(this.store_Pessoa.pessoa_edicao);
        // return
        //Verifica se o socio já está vinculado a este cadastro
        for(let value of this.store_Pessoa.pessoa_edicao.pessoa_socios1){
          if(value.Pessoa_Socio.cod_pessoa_socio ==  this.store_Pessoa.pessoa_conjuge_selecionado.cod_pessoa){
            this.store_site.alert_cor       = "#A00000";
            this.store_site.alert_timeout   = 5000;
            this.store_site.alert_msg       = "Sócio já cadastrado";
            this.store_site.alert           = true;
            this.loading = false
            return
          }
        }
  
        //Monta os dados para fazer a requisição
        let aux = {};
        aux.cod_pessoa_juridica = this.store_Pessoa.pessoa_edicao.cod_pessoa;
        aux.cod_pessoa_socio = this.store_Pessoa.pessoa_conjuge_selecionado.cod_pessoa;
  
        //Verifica se foi digitado a porcentagem
        if(!this.editar_porc_aux){
          aux.pessoa_socio_porc = 0
        }else{
          aux.pessoa_socio_porc = this.editar_porc_aux;
        }
  
        //Verifica se foi informado se é titular
        if(!this.select_socio_aux){
          aux.pessoa_socio_titular = "N"
        }else{
          aux.pessoa_socio_titular = this.select_socio_aux;
        }
  
        if (this.store_Pessoa.pessoa_edicao.pessoa_socios1.length == 0) {
          aux.pessoa_socio_titular = 'S'
          // console.log("aux", aux);
        }
        let lo_Socio = {...aux, acao: "I"};
        // console.log('socio', this.store_Pessoa.pessoa_edicao);
        // return
        let ls_Socio = await this.store_Pessoa.PessoaSocioPost(lo_Socio)
  
        //Atualiza a tabela com os dados novos
        let auxiliar = {};
        auxiliar = { Pessoa_Socio: aux }
        auxiliar.pessoa_nome = this.store_Pessoa.pessoa_conjuge_selecionado.pessoa_nome
        this.store_Pessoa.pessoa_edicao.pessoa_socios1.push(auxiliar);
        if(this.select_socio_aux == "S"){
          for(let value of this.store_Pessoa.pessoa_edicao.pessoa_socios1){
            if(value.Pessoa_Socio.cod_pessoa_socio != this.store_Pessoa.pessoa_conjuge_selecionado.cod_pessoa){
              value.Pessoa_Socio.pessoa_socio_titular = "N";
            }
          }
        }
  
        //Atualiza o banco com os novos dados do titular
        let novo_lo_Socio
        let novo_ls_Socio
        if(this.select_socio_aux == "S"){
          for(let value of this.store_Pessoa.pessoa_edicao.pessoa_socios1){
            if(value.Pessoa_Socio.cod_pessoa_socio != this.store_Pessoa.pessoa_conjuge_selecionado.cod_pessoa){
              value.Pessoa_Socio.pessoa_socio_titular = "N";
              let novoValue = value.Pessoa_Socio;
              novo_lo_Socio = {...novoValue, acao: "E"}
              novo_ls_Socio = await this.store_Pessoa.PessoaSocioPost(novo_lo_Socio)
            }
          }
        }
  
        //Retorno do backend
        if ((ls_Socio.result && ls_Socio.result.message && ls_Socio.result.message == 'success') ||
            (ls_Socio.status && ls_Socio.status == 200)) {
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_Socio.result.result.trim();
            this.store_site.alert           = true;
          } else {
            this.store_site.alert_cor       = "#A00000";
            this.store_site.alert_timeout   = 20000;
            this.store_site.alert_msg       = ls_Socio.errors.trim();
            this.store_site.alert           = true;
          }
  
        this.loading = false
        this.dialogSociosCadastro              = false
        this.store_Pessoa.dialogSociosCadastro = false
      }
    },
    busca_timeout(val) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(async () => {
        const ls_Rel_Municipios = await API.get(`municipio`, val);
        if (Array.isArray(ls_Rel_Municipios.data.result)) {
          ls_Rel_Municipios.data.result.map(el => el.nome = `${el.nome} - ${el.uf}`)
          this.arrayMunicipios = ls_Rel_Municipios.data.result;
          // console.log("arrayMunicipios dentro do search",this.arrayMunicipios)
        }
      }, 600);
    },

    querySearch(query){
      let p_params = { params: { filtro: query } }
      //console.log("p_params", p_params);
      this.loading = true
      this.busca_timeout(p_params);
      //console.log('entreiiiiii');
      this.loading = false

    },  
    clickBtnEditar(){
      this.store_Pessoa.acao = 'E'; 
      this.store_Pessoa.acao_abas = true; 
      this.pessoa_edicaoAux={...store_Pessoa.pessoa_edicao}; 
      if (!!this.store_Pessoa.pessoa_edicao.pessoa_municipio) {
        this.arrayMunicipios = [
                                  { 'codigo': store_Pessoa.pessoa_edicao.cod_municipio, 
                                    'nome': store_Pessoa.pessoa_edicao.pessoa_municipio.nome + ' - ' + store_Pessoa.pessoa_edicao.pessoa_municipio.uf
                                  }
                                ]                       
      }

    },
   
    // save() {
    //   this.snack = true;
    //   this.snackColor = "success";
    //   this.snackText = "Data saved";
    // },
    // cancel() {
    //   this.snack = true;
    //   this.snackColor = "error";
    //   this.snackText = "Canceled";
    // },
    // open() {
    //   //store_Pessoa.dialogEnderecosCadastro = true;
    //   this.snack = true;
    //   this.snackColor = "info";
    //   this.snackText = "Dialog opened";
    // },
    // close() {
    //   this.snack = true;
    //   this.snackColor = "info";
    //   this.snackText = "Dialog closed";
    // },

    // maxValue(max) {
    //   return max;
    // },

    selecionarConjuge(elem) {
      if (!elem) {
        this.conjuge = {  cod_pessoa       : null,
                          pessoa_nome      : null,
                          pessoa_cpf       : null,
                          pessoa_nasc_dt   : null,
                          pessoa_profissao : null
                        }
        this.store_Pessoa.pessoa_edicao.pessoa_casamento_dt = null;
        this.store_Pessoa.pessoa_edicao.cod_regime          = null;
        this.store_Pessoa.pessoa_edicao.pessoa_conjuge      = {...this.conjuge};
        this.store_Pessoa.pessoa_edicao.cod_conjuge         = null;
      } 
      else {
        this.conjuge = elem;
        this.store_Pessoa.pessoa_edicao.cod_conjuge = this.conjuge.cod_pessoa;
      }

      this.store_Pessoa.dialogConjugeExcluir  = false
      this.store_Pessoa.dialogConjuge         = false
    },

    startCameraStream() {
      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          console.log(
            "Browser doesn't support or there is some errors." + error
          );
        });
    },

    async getDevices() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        //console.log("NAO")
        return false;
      }
      try {
        let allDevices = await navigator.mediaDevices.enumerateDevices();
        //console.log("SIM", allDevices)
        for (let mediaDevice of allDevices) {
          if (mediaDevice.kind === "videoinput") {
            let option = {};
            option.text = mediaDevice.label;
            option.value = mediaDevice.deviceId;
            this.options.push(option);
            this.devices.push(mediaDevice);
          }
        }
        return true;
      } catch (err) {
        throw err;
      }
    },

    // --------------------------------------------------------------------------
    abre() {
      if (!this.store_Pessoa.pessoa_edicao.pessoa_foto) {
        this.$refs.file_new.click();
      } else {
        this.$refs.file_update.click();
      }
    },

    async municipios() {
      const ls_Rel = await API.get(`municipio`);
      this.arrayMunicipios = ls_Rel.data.result;
    },

    // Abre Modal do Endereço -------------------------
    ModalEndereco(item) {
      if (!item) {
        if (this.store_Pessoa?.pessoa_edicao?.pessoa_enderecos?.length <= 0)
          this.dados_modal_endereco = {pessoa_endereco_corresp : "S"}
        else
          this.dados_modal_endereco = {pessoa_endereco_corresp : "N"};
      }
      else
        this.dados_modal_endereco = {...item};
      this.dados_modal_endereco.cod_pessoa = this.store_Pessoa.pessoa_edicao.cod_pessoa;
      this.store_Pessoa.dialogEnderecosCadastro = true;
    },

    // Abre Modal do Contato -------------------------
    ModalContato(item) {
      if (!item) {
        this.dados_modal_contato = {acao: 'I'}
      }
      else
        this.dados_modal_contato = {...item};
      this.dados_modal_contato.cod_pessoa = this.store_Pessoa.pessoa_edicao.cod_pessoa;
      this.store_Pessoa.dialogContatosCadastro = true;
    },

    // Abre Modal do Bancario -------------------------
    async ModalBancario(item) {
      this.store_Pessoa.dialogDadosBancariosCadastro = null;
      const lo_bancos = await BancosGet({params: {fields: 'cod_banco, banco_nome, banco_cod_compensacao'}});
      this.dados_modal_bancario_bancos      = lo_bancos;

      if (!item) {
        this.dados_modal_bancario  = {acao: 'I', cod_banco: null};
      }
      else {
        this.dados_modal_bancario = {...item};
        this.dados_modal_bancario.acao = 'E';
        if (item.pessoa_conta_agencia)
          this.dados_modal_bancario.cod_banco   = item.pessoa_conta_agencia.cod_banco;
      }

      this.dados_modal_bancario.cod_pessoa = this.store_Pessoa.pessoa_edicao.cod_pessoa;
      this.store_Pessoa.dialogDadosBancariosCadastro = true;
    },

    async excluir_relacionados(p_Tipo, item) {
      var ls_Rel = null;
      item.acao = 'D';
      if (p_Tipo == 'ENDERECOS')
        ls_Rel = await API.post(`pessoa_endereco`, JSON.stringify(item));
      else if (p_Tipo == 'CONTATOS')
        ls_Rel = await API.post(`pessoa_contato`, JSON.stringify(item));
      else if (p_Tipo == 'CONTAS')
        ls_Rel = await API.post(`pessoa_conta`, JSON.stringify(item));
      if (ls_Rel.status == 200) {
        if (p_Tipo == 'ENDERECOS'){
          if (this.store_Pessoa.pessoa_edicao.pessoa_enderecos.length > 1 && item.pessoa_endereco_corresp === 'S') {
            let update = {}
            update = {...ls_Rel.data.result.data[0]}
            update.acao = 'E'
            update.pessoa_endereco_corresp = 'S'
            ls_Rel = await API.post(`pessoa_endereco`, JSON.stringify(update));
          }

          this.store_Pessoa.pessoa_edicao.pessoa_enderecos = ls_Rel.data.result.data
        }
        if (p_Tipo == 'CONTATOS')
          this.store_Pessoa.pessoa_edicao.pessoa_contatos = ls_Rel.data.result.data
        if (p_Tipo == 'CONTAS')
          this.store_Pessoa.pessoa_edicao.pessoa_contas = ls_Rel.data.result.data
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = ls_Rel.data.result.result.trim();
        this.store_site.alert           = true;
      } else {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = ls_Rel.data.errors.trim();
        this.store_site.alert           = true;
      }
      this.dialog_excluir = false;
    },

    cancelar() {
      this.store_Pessoa.acao_abas = false
      if (this.store_Pessoa.acao == 'I') {
        this.pessoa_edicaoAux = null;
        this.selecionarConjuge();
        this.$router.push("pessoaconsulta");
      }
      else if (this.store_Pessoa.acao == 'E') {
        this.store_Pessoa.pessoa_edicao = {...this.pessoa_edicaoAux};
        this.conjuge                    = {...this.store_Pessoa.pessoa_edicao.pessoa_conjuge};
      }
      this.store_Pessoa.acao = 'C';
    },

    async validate() {
      const lb_valido = this.$refs.form.validate();
      if (lb_valido) {
        // this.loading = true
        var lo_JSON = { ...this.store_Pessoa.pessoa_edicao };
        lo_JSON.objeto_dados_cnpj = this.objeto_dados_cnpj 
        console.log("🚀 ~ file: PessoaCadastro.vue:2664 ~ validate ~ lo_JSON.objeto_dados_cnpj:", lo_JSON.objeto_dados_cnpj)

        // Editando Contato ----------------
        var ls_Res;
        lo_JSON.cod_conjuge = this.conjuge ? this.conjuge.cod_pessoa : null
        if (this.store_Pessoa.acao == "E") {
          
        
          // console.log('EDICAO',this.conjuge.cod_pessoa)
          // return
          ls_Res = await this.store_Pessoa.PessoaPut(lo_JSON);
          // Incluindo Contato ----------------
        } else {
          // console.log('INSERCAO',this.conjuge.cod_pessoa, this.store_Pessoa.acao)
          // return
          ls_Res = await this.store_Pessoa.PessoaPost(lo_JSON);
        }

        this.loading = false
        if ((ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') ||
            (ls_Res.status && ls_Res.status == 200)) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Res.result.result.trim();
          this.store_site.alert           = true;

          // Buscando Novamente o Registro Salvo
          var lo_params = { cod_pessoa: ls_Res.result.data.cod_pessoa };
          const lo_Res = await this.store_Pessoa.PessoaGet(lo_params);
          // console.log("lo_Res", lo_Res);
          // return
          if ( lo_Res.rows.length > 0)
            this.store_Pessoa.pessoa_edicao = { ...lo_Res.rows[0] };
          this.store_Pessoa.acao  = "C";
          this.pessoa_edicaoAux       = this.store_Pessoa.pessoa_edicao;
        } else {
          this.store_site.alert_cor       = "#A00000";
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = ls_Res.errors.trim();
          this.store_site.alert           = true;
        }
      }
      this.store_Pessoa.acao_abas = false
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    /* Método que recebe arquivo de imagem */
    handleFile({ target }) {
      this.dialog_crop = true;
    },

    togglePlus () {
      this.plus = !this.plus
    },

  },
};
</script>

<style scoped>
#Pessoa_DadosBasicos {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #Pessoa_DadosBasicos {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#Pessoa_DadosBasicos::-webkit-scrollbar {
  width: 5px;
}

#Pessoa_DadosBasicos::-webkit-scrollbar-button {
  padding: 1px;
}

#Pessoa_DadosBasicos::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#Pessoa_DadosBasicos::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #Pessoa_DadosBasicos::-webkit-scrollbar {
    width: initial;
  }

  #Pessoa_DadosBasicos::-webkit-scrollbar-button {
    padding: initial;
  }

  #Pessoa_DadosBasicos::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #Pessoa_DadosBasicos::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 17px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px!important;
  color: #9E9E9E;
}

.my-custom-dialog {
  align-self: center;
  position: absolute;
  bottom: 0;
  right: 0;
  color: red;
}

.flex-column {
  flex-direction: column!important;
}

.input-rg {
  margin-top: -18px;
}

.input-inscricao {
  margin-top: -22px;
}

@media(max-width: 599px) {
  .input-rg {
    margin-top: -8px;
  }

  .input-inscricao {
    margin-top: -10px;
  }

  .col-terceira-pf div:nth-child(2), .col-terceira-pj div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pj div:nth-child(1) {
    margin-bottom: 4px;
  }

  .col-terceira-pf div:nth-child(3), .col-terceira-pj div:nth-child(3) {
    margin-top: -12px;
  }

  .col-terceira-pj div:nth-child(3) {
    margin-bottom: -4px;
  }

  .col-quarta-pf div:nth-child(2), .col-quarta-pj div:nth-child(2) {
    margin-top: 13px;
    margin-bottom: -10px;
  }

  .col-quinta-pj div:nth-child(3) {
    margin-top: -12px;
  }

  .telefones div:not(.telefones div:first-child) {
    margin-top: -8px;
  }

  .input-nome {
    margin-bottom: -8px!important;
  }

  .input-razao {
    margin-bottom: -12px!important;
  }

  .input-date {
    margin-top: -12px;
  }

  .input-conjuge, .input-date-nasc, .input-date-cas {
    margin-top: -8px;
  }
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  padding: 2px;
  background: var(--COR_SECUNDARIA)!important;
  border-top-width: 1px!important;
  border-top-style: solid!important;
  border-top-color: var(--COR_SOMBRA)!important;
}

/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 10px;
  width: 200px !important;
} */

</style>
                
